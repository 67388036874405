import React from "react"

import Link from "next/link"

import styles from '../styles/not-found.module.scss'

export default function Custom404() {
  return (
    <div className={styles['notfound-wrapper']}>
      <div className={styles['notfound']}>
        <div className={styles['notfound-404']}>
          <h1>Oops!</h1>
          <h2>
            404 - The Page can&apos;t be
            {' '}
            <span>found</span>
          </h2>
        </div>
        <Link
          href="/"
        >
          Go TO Homepage
        </Link>
      </div>
    </div>
  )
}